import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDYqcc4pGWH0SYSQtYquHO7Ytujgto3Eg8',
  appId: '1:550772834910:web:7ee63409a7784a53ae6acf',
  authDomain: 'batteries-fb.firebaseapp.com',
  databaseURL: 'https://batteries-fb.firebaseio.com',
  projectId: 'batteries-fb',
  storageBucket: 'batteries-fb.appspot.com',
  measurementId: 'G-C7EWL86ZBG',
  messagingSenderId: '550772834910',
};
const Fire = firebase.initializeApp(firebaseConfig);

export const Auth = Fire.auth();

import {useEffect, useState} from 'react';
import axios from 'axios';
import {Auth} from 'firebaseConfig';
const getCookie = cookieName => {
  const decodedCookie = decodeURIComponent(document.cookie);
  // Multiple cookies are seperated by ';' and need to be split into separate items in an array for proper parsing
  const cookies = decodedCookie.split(';');
  // Look through all items of split array to find desired cookie; Return entire cookie string (includes cookie name and cookie value);
  const foundCookie = cookies.find(c => c.includes(cookieName));
  // If cookie found, remove the cookie name so that only the cookie value remains; Then return the cookie value;
  if (foundCookie) return foundCookie.replace(cookieName + '=', '').trim();
  // If no cookie found, return null
  else return null;
};

const useAuthCheck = () => {
  const [authentication, setAuthentication] = useState({currentUser: null, checked: false});
  const [csstCookie, setCsstCookie] = useState({value: null, checked: false});

  useEffect(() => {
    const interval = setInterval(() => {
      // Use getCookie function to parse out csst cookie and set to intervalCookie
      // getCookie will retun null when no csst cookie is found
      const intervalCookie = getCookie('csst');

      // When previous csstCookie.value from useAuthCheck state is the same as new intervalCookie, do nothing.
      if (csstCookie.value === intervalCookie && csstCookie.checked) return;
      // Otherwise set csstCookie.value to new intervalCookie
      else setCsstCookie({value: intervalCookie, checked: true});

      // Run cookie check immediataley when useAuthCheck is first mounted and every 2 seconds thereafter.
    }, csstCookie.checked ? 2000 : 0);
    return () => clearInterval(interval);
  }, [csstCookie.checked, csstCookie.value]);

  useEffect(() => {
    const csstLogin = async () => {
      try {
        const response = await axios({
          method: 'post',
          url: 'https://server.kbi.works/auth/check-status',
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${csstCookie.value}`,
          },
        });
        const customToken = response.data;
        await Auth.setPersistence('none');
        await Auth.signInWithCustomToken(customToken);
      }
      catch (error) {
        console.log('csstLogin Error: ', error);
      }
    };
    const authListener = Auth.onAuthStateChanged(user => {
      const previousURL = getCookie('previousURL');
      if (!csstCookie.checked) return;
      else if (user && csstCookie.value) {
        if (previousURL) {
          document.cookie = `previousURL='';domain=kbi.works;max-age=0`;
          document.cookie = `signOut='';domain=kbi.works;max-age=0`;
          window.location.replace(previousURL);
        }
        else setAuthentication({currentUser: user, checked: true});
      }
      else if (user && !csstCookie.value) Auth.signOut();
      else if (!user && csstCookie.value) csstLogin();
      else setAuthentication({currentUser: null, checked: true});
    });
    return () => authListener();
  }, [csstCookie.value, csstCookie.checked]);

  return authentication;
};

export default useAuthCheck;

import {useEffect, useState} from 'react';
import axios from 'axios';

const useBackgroundImage = () => {
  const [backgroundImage, setBackgroundImage] = useState(null);

  useEffect(() => {
    (async () => {
      const reqOptions = {
        method: 'get',
        url: `https://ik.imagekit.io/kbi/kbi-catalyst/App-Assets/backgrounds/background1.jpg`,
        responseType: 'blob',
      };
      try {
        const response = await axios(reqOptions);
        const objectURL = URL.createObjectURL(response.data);
        setBackgroundImage(objectURL);
      }
      catch (error) {
        // Set backgroundImage to undefined to default to no image background in case of unsplash API failure
        setBackgroundImage(undefined);
      }
    })();
  }, []);

  return backgroundImage;
};

export default useBackgroundImage;

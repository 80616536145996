import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {ModuleSelector, SignIn} from 'views';
import {useAuthCheck, useBackgroundImage} from 'hooks';
import BackgroundCover from 'components/BackgroundCover';

const App = () => {
  const authorized = useAuthCheck();
  const backgroundImage = useBackgroundImage();

  if (!authorized.checked || backgroundImage === null) return <LinearProgress />;
  return (
    <BackgroundCover backgroundImage={backgroundImage}>
      {!authorized.currentUser && <SignIn backgroundImage={backgroundImage} />}
      {authorized.currentUser && <ModuleSelector backgroundImage={backgroundImage} />}
    </BackgroundCover>
  );
};

export default App;

import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, Paper, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Alert} from '@material-ui/lab';
import axios from 'axios';
import {FormikForm, SubmitButton, TextField} from '@kbi/story-forms';
import {object, string} from 'yup';
import {Auth} from 'firebaseConfig';
import logo from 'media/KBI-Logo.png';
import PreviousUrlTimer from './SignIn/PreviousUrlTimer';
const host = process.env.NODE_ENV === 'production' ? 'server.kbi.works' : 'localhost:8080';

const SignIn = ({backgroundImage}) => {
  const [stage, setStage] = useState('SignIn');
  const styles = useStyles();

  const formProps = useMemo(() => ({
    initialStatus: {text: '', severity: 'warning'},
    initialValues: {Email: '', Password: ''},
    onSubmit: (values, actions) => {
      const {Email, Password} = values;
      const handleSignIn = async () => {
        try {
          await Auth.setPersistence('none');
          const {user} = await Auth.signInWithEmailAndPassword(Email, Password);
          const idToken = await user.getIdToken(true);
          axios({
            method: 'post',
            url: `https://${host}/auth/signin`,
            withCredentials: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            data: {idToken},
          });
        }
        catch (error) {
          actions.setStatus({text: error.message, in: true, severity: 'error'});
          actions.setSubmitting(false);
        }
      };
      const handleForgotPassword = async () => {
        try {
          await Auth.sendPasswordResetEmail(Email);
          actions.setStatus({text: 'We have sent you a password reset email.', in: true, severity: 'success'});
          actions.setSubmitting(false);
        }
        catch (error) {
          actions.setStatus({text: error.message, in: true, severity: 'error'});
          actions.setSubmitting(false);
        }
      };
      if (stage === 'SignIn') handleSignIn();
      else if (stage === 'ForgotPassword') handleForgotPassword();
    },
    validationSchema: () => {
      const forgotPasswordValidation = object().shape({
        Email: string().required().email(),
      });
      const signInValidation = object().shape({
        Email: string().required().email(),
        Password: string().required().min(5),
      });
      if (stage === 'ForgotPassword') return forgotPasswordValidation;
      if (stage === 'SignIn') return signInValidation;
    },
  }), [stage]);
  const forgotPasswordBtnProps = useMemo(() => ({
    gutterBottom: true,
    style: {
      cursor: 'pointer',
      float: 'right',
      marginTop: '4px',
    },
    variant: 'caption',
    onClick: () => {
      if (stage === 'SignIn') setStage('ForgotPassword');
      else if (stage === 'ForgotPassword') setStage('SignIn');
    },
  }), [stage]);

  return (
    <div className={styles.layout}>
      <Paper className={styles.paper} elevation={4}>
        <div className={styles.logoWrapper}>
          <img alt='EV Battery Returns Logo' src={logo} className={styles.logo} />
        </div>
        <Typography variant='h5' align='center' className={styles.headerText}>Company Portal</Typography>
        <Typography variant='h5' align='center' className={styles.headerText}>User Authentication</Typography>
        <PreviousUrlTimer />
        <FormikForm {...formProps}>
          {(formik) => (<Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField name='Email' fast required autoComplete='username' />
            </Grid>
            {stage === 'SignIn' && <Grid item xs={12}>
              <TextField name='Password' type='password' required autoComplete='current-password' />
            </Grid>}
            {Boolean(formik.status.text) && (<Grid item xs={12}>
              <Alert severity={formik.status.severity || 'info'} style={{margin: '8px 0px -12px 0px'}}>
                {formik.status.text}
              </Alert>
            </Grid>)}
            <Grid item xs={12}>
              <div style={{marginTop: '24px'}}>
                <SubmitButton fullWidth>
                  {stage === 'SignIn' && 'Sign In'}
                  {stage === 'ForgotPassword' && 'Reset Password'}
                </SubmitButton>
                <Typography {...forgotPasswordBtnProps}>
                  {stage === 'SignIn' && 'Forgot Password?'}
                  {stage === 'ForgotPassword' && 'Remembered Password?'}
                </Typography>
              </div>
            </Grid>
          </Grid>)}
        </FormikForm>
      </Paper>
    </div>
  );
};

SignIn.propTypes = {
  backgroundImage: PropTypes.string,
};
const useStyles = makeStyles(theme => ({
  headerText: {
    fontSize: '1.2rem',
  },
  layout: {
    alignItems: 'flex-start',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    overflowY: 'auto',
    position: 'absolute',
    top: 0,
    width: '100vw',
  },
  logo: {
    height: '100px',
    marginBottom: '24px',
    marginTop: '24px',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    border: '1px solid lightgray',
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(6),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  timerText: {
    paddingTop: '8px',
    paddingBottom: '16px',
  },
}));
export default SignIn;

import React, {Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

const BackgroundCover = ({backgroundImage, children}) => {
  const styles = useStyles();
  const backgroundImageProps = useMemo(() => ({
    style: {
      backgroundSize: 'cover',
      backgroundImage: `url(${backgroundImage})`,
      opacity: 0.7,
      height: '100vh',
      width: '100vw',
    },
  }), [backgroundImage]);

  return (
    <Fragment>
      <div {...backgroundImageProps} />
      <div className={styles.overlay} />
      {children}
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  overlay: {
    backgroundColor: 'white',
    opacity: 0.3,
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
  },
}));
BackgroundCover.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
export default React.memo(BackgroundCover);

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

const getCookie = cookieName => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');
  const foundCookie = cookies.find(c => c.includes(cookieName));
  if (foundCookie) return foundCookie.replace(cookieName + '=', '').trim();
  else return null;
};
const millisToMinutesAndSeconds = (millis) => {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
};

const PreviousUrlTimer = () => {
  const styles = useStyles();
  const signOutCookie = getCookie('signOut');

  if (!signOutCookie) return null;
  const endTime = parseInt(signOutCookie.split('.')[1]) + 600000; // add 10 min in ms to get cookie max-age
  const timeDifference = endTime - Date.now();
  return (
    <Typography variant='caption' align='center' className={styles.timerText}>
      {`Login within ${millisToMinutesAndSeconds(timeDifference)} to continue from your previous location.`}
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  timerText: {
    paddingTop: '8px',
    paddingBottom: '16px',
  },
}));
export default PreviousUrlTimer;

import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Button, Divider, MenuItem, MenuList, Paper, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import logo from 'media/KBI-Logo.png';

const ModuleSelector = ({backgroundImage}) => {
  const [signout, setSignout] = useState(false);
  const styles = useStyles();
  const handleLogout = useCallback(async () => {
    setSignout(true);
    try {
      await axios({
        method: 'post',
        url: 'https://server.kbi.works/auth/signout',
        withCredentials: true,
      });
    }
    catch (error) {
      setSignout(false);
      console.error(error);
    }
  }, []);

  return (
    <div className={styles.layout}>
      <Paper className={styles.paper} elevation={4}>
        <div className={styles.logoWrapper}>
          <img alt='EV Battery Returns Logo' src={logo} className={styles.logo} />
        </div>
        <Typography variant='h5' align='center' className={styles.headerText}>Company Portal</Typography>
        <Typography variant='h5' align='center' className={styles.headerText}>Module Selection</Typography>
        <MenuList>
          <Divider />
          <MenuItem onClick={() => window.location.assign('https://crm.kbi.works/')}>Customer Relationship Mgmt.</MenuItem>
          <Divider />
          <MenuItem onClick={() => window.location.assign('https://ehs.kbi.works/')}>Environmental, Health & Safety</MenuItem>
          <Divider />
          <MenuItem onClick={() => window.location.assign('https://tracking.kbi.works/')}>Waste Tracking</MenuItem>
          <Divider />
          <MenuItem onClick={() => window.location.assign('https://admin.kbi.works/')}>Admin Portal</MenuItem>
          <Divider />
        </MenuList>
        <Button fullWidth variant='outlined' color='secondary' onClick={handleLogout} disabled={signout} style={{marginTop: '32px'}}>
          Logout
        </Button>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  headerText: {
    fontSize: '1.2rem',
  },
  layout: {
    alignItems: 'flex-start',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    overflowY: 'auto',
    position: 'absolute',
    top: 0,
    width: '100vw',
  },
  logo: {
    height: '100px',
    marginBottom: '24px',
    marginTop: '24px',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  overlay: {
    backgroundColor: 'white',
    opacity: 0.3,
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
  },
  paper: {
    border: '1px solid lightgray',
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(6),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
}));
ModuleSelector.propTypes = {
  backgroundImage: PropTypes.string,
};
export default ModuleSelector;
